<template>
    <b-container fluid>
      <b-row v-if="rowsShow.length > 0">
        <b-col>
          <div v-for="row in rowsShow" :key="'recursive-row-'+ row.id">
            <router-link :to="{ name: 'Process', params: { id: row.id }}"><h5>{{row.numberLong}} - {{row.name}}</h5></router-link>
            <process-recursive-simple
              :rows="rows"
              :parent_id="row.id"
              :level="level + 1"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'ProcessRecursiveSimple',
  props: ['level', 'parent_id', 'rows'],
  computed: {
    rowsShow: function () {
      let results = []
      this.rows.forEach(x => {
        if (x.parent_id === this.parent_id) {
          results.push(x)
        }
      })
      results = _.orderBy(results, ['number'], ['asc'])
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>
