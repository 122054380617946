<template>
  <b-col>
    <b-form inline>
      <!-- <b-button variant="outline-primary" @click="download" class="mr-2 button">download</b-button> -->
      <!-- <b-form-select class ="button" v-model="viewSelected" :options="viewOptions" id="view-select"></b-form-select> -->
      <!--
      sort by:
      <b-form-select class="mx-2" v-model="sortProperty" :options="sortPropertyOptions"></b-form-select>
      <b-form-select v-model="sortDirection" :options="sortDirectionOptions"></b-form-select>
      -->
    </b-form>
    <b-card class="my-3 mb-2" v-if="rowsShow.length > 0 && rowsShow.length < rows.length">
      <h3>Search results:</h3>
      <div v-for="item in rowsShow" :key="'searchrow'+item.id">
        <router-link :to="{ name: 'Process', params: { id: item.id }}"><h5>{{item.numberLong}} - {{item.name}}</h5></router-link>
      </div>
    </b-card>
    <b-card class="my-3 mb-2">
      <line-item
        :rows="rows"
        :parent_id="null"
        :level="0"
      />
    </b-card>
  </b-col>
</template>

<script>
import LineItem from '@/components/processes/RecursiveDown.vue'

export default {
  name: 'Processes',
  components: {
    LineItem
  },
  computed: {
    rowsShow () {
      return this.$store.state.rowsShow
    },
    rows () {
      return this.$store.state.rows
    },
    sortDirection: {
      get () {
        return this.$store.state.sortDirection
      },
      set (payload) {
        this.$store.commit('setSortDirection', payload)
      }
    },
    sortProperty: {
      get () {
        return this.$store.state.sortProperty
      },
      set (payload) {
        this.$store.commit('setSortProperty', payload)
      }
    },
    sortPropertyOptions () {
      return this.$store.state.sortPropertyOptions
    },
    totalRows () {
      return this.rowsShow.length
    }
  },
  created: function () {
    this.$stat.log({ page: 'processes', action: 'open processes' })
    console.log(this.rowsShow)
  },
  data () {
    const data = {
      sortDirectionOptions: [
        { value: 'asc', text: 'ascending' },
        { value: 'desc', text: 'descending' }
      ]
    }
    return data
  },
  methods: {
  }
}
</script>

<style>
</style>
